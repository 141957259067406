/* popup */

.popup {
    display: flex;
    align-items: stretch;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 240px);
    left: calc(50% - 484px);
    width: 968px;
    min-height: 472px;
}

.iconWrapper {
    position: relative;
    width: 69px;
    background-color: var(--primary-400-base);
}

.icon {
    position: absolute;
    width: 40px;
    height: 46px;
    top: 51px;
    left: calc(50% - 20px);
    fill: var(--whitebase);
}

.body {
    padding-top: 23px;
    padding-bottom: 32px;
    padding-right: 63px;
    padding-left: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.closeButtonWrapper {
    align-self: flex-end;
}

.title {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 52px;
    color: var(--black-base);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 17px;
    margin-left: 4px;
    margin-top: 1px;
}

.inner {
    display: grid;
    grid-template-columns: 227px 260px 1fr 260px;
    margin-bottom: 22px;
}

.buttonWrapper {
    margin-top: 62px;
    margin-left: 2px;
    display: flex;
    justify-content: space-between;
}

.buttonInner {
    width: 202px;

    display: flex;
    justify-content: space-between;
}
